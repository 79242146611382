import React, { useState, useEffect } from 'react';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import toast, { Toaster } from 'react-hot-toast';

// In CertificateUpload.js
const CertificateUpload = ({ uploadCertificateUrl }) => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const baseURL = process.env.NODE_ENV === 'development'
        ? 'http://localhost:4001'
        : 'https://register.ugonsa.org';

    const uppy = new Uppy({
        meta: { type: 'certificate' },
        restrictions: {
            maxNumberOfFiles: 1,
            maxFileSize: 5242880,
            allowedFileTypes: ['.pdf', '.doc', '.docx', 'image/*']
        },
        autoProceed: true
    });

    //http://localhost:4001/v1/api/upload/certificate

    uppy.use(XHRUpload, {
        endpoint: `https://register.ugonsa.org/v1/api/upload/certificate`,
        method: 'post',
        formData: true,
        fieldName: 'certificate',
    });

    uppy.on('upload-error', (file, error, response) => {
        console.error('Upload error:', error, response);
        toast.error('Upload failed: ' + (error.message || 'Unknown error'));
    });

    uppy.on('upload-progress', (file, progress) => {
        const progressValue = Math.round((progress.bytesUploaded / progress.bytesTotal) * 100);
        setUploadProgress(progressValue);
    });

    // init onComplete uppy function
    uppy.on('complete', (result) => {

        // check if result
        if (!result.successful && result.successful[0].response.body.success) {
            // return error
            console.log(result.successful[0].response.body.message)
            return toast.error(result.successful[0].response.body.message)
        }

        // console.log(result.successful[0].response.body);
        // console.log(result.successful[0].response.body.message);

        // if success, pass to parent
        uploadCertificateUrl(result.successful[0].response.body.data.secure_url);

    })

    return (
        <>
            <Dashboard
                uppy={uppy}
                height={250}
                width="100%"
            />
            {uploadProgress > 0 && uploadProgress < 100 && (
                <div className="mt-2">
                    <small className="text-muted">Uploading: {uploadProgress}%</small>
                </div>
            )}
        </>
    );
};

export default CertificateUpload;